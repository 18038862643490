import EditIcon from "@mui/icons-material/Edit"
import { IconButton, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { addDays, addMonths, format } from "date-fns"
import React from "react"
import { ProductDto, ResponseWorkDto } from "src/api/generated"
import { getProducts } from "src/api/queries/getProducts"
import { getWork } from "src/api/queries/getWork"
import { useCustomSnack } from "src/hooks/useCustomSnack"
import CustomTableRow, { RowColor } from "./CustomTableRow"
import DeleteWorkButton from "./DeleteWorkButton"
import EditWorkModal from "./EditWorkModal"
import TableRowDetails from "./TableRowDetails"
import TableSpinner from "./TableSpinner"
import TableTitleSection from "./TableTitleSection"

interface Props {
  isHandled: boolean
  searchQuery: string
  rowColor: RowColor
  title: string
  editable: boolean
}

const WorkTable = (props: Props) => {
  const { showSnack } = useCustomSnack()
  const [workToEdit, setWorkToEdit] = React.useState<ResponseWorkDto | undefined>()
  const [fromDate, setFromDate] = React.useState<string | undefined>(
    new Date(addDays(Date.now(), -7)).toISOString()
  )

  const [toDate, setToDate] = React.useState<string | undefined>(
    props.isHandled ? new Date().toISOString() : new Date(addMonths(Date.now(), 1)).toISOString()
  )

  const { data, isLoading } = useQuery<ResponseWorkDto[], Error>(
    ["getWork", props.isHandled, props.searchQuery, fromDate, toDate],
    () => getWork(props.isHandled, props.searchQuery, fromDate, toDate),
    {
      onSuccess: (data) => {
        var oldUnhandledWorks = data.filter(
          (work) =>
            !work.handled &&
            Date.parse(work.dateOfDelivery) < Date.now() - 24 * 60 * 60 * 1000 &&
            Date.parse(work.createdAtUtc) < Date.now() - 24 * 60 * 60 * 1000
        )
        if (oldUnhandledWorks.length > 0) {
          showSnack(
            `${oldUnhandledWorks.length} Chargefile-linje(r) er ikke blevet sendt til OpenNet i nat! kontakt support!`,
            "error"
          )
        }
      },
    }
  )

  const productsQuery = useQuery<ProductDto[], Error>(["getProducts"], getProducts)
  const findProduct = (productId: string) => productsQuery.data?.find((p) => p.id === productId)

  return (
    <div>
      <TableTitleSection
        title={props.title}
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChangeHandler={setFromDate}
        onToDateChangeHandler={setToDate}
      />
      <StyledTable data-testid="work-table">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader></StyledTableCellHeader>
            <StyledTableCellHeader>Dato</StyledTableCellHeader>
            <StyledTableCellHeader>Produkt</StyledTableCellHeader>
            <StyledTableCellHeader>Transaktionskode</StyledTableCellHeader>
            <StyledTableCellHeader $alignRight={true}>Pris</StyledTableCellHeader>
            {props.editable && (
              <StyledTableCellHeader $alignRight={true}>Handlinger</StyledTableCellHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && <TableSpinner />}
          {data &&
            data.map((work: ResponseWorkDto) => {
              var product = findProduct(work.productId)
              return (
                <CustomTableRow
                  rowColor={props.rowColor}
                  key={work.id}
                  cells={[
                    { value: format(new Date(work.dateOfDelivery), "dd-MM-yyyy") },
                    { value: product?.name || "" },
                    { value: product?.transactionCode || "" },
                    { value: work.totalPrice.toString(), alignRight: true },
                  ]}
                  actionsCell={
                    props.editable && (
                      <>
                        <IconButton size="small" onClick={() => setWorkToEdit(work)}>
                          <EditIcon />
                        </IconButton>
                        <DeleteWorkButton workId={work.id} />
                      </>
                    )
                  }
                  collapsedChildren={
                    <TableRowDetails
                      items={[
                        ["SSID", work.serviceSubscriptionId],
                        ["Antal enheder", work.priceVolume.toString()],
                        ["Beskrivelse", work.description],
                      ]}
                    />
                  }
                />
              )
            })}
        </TableBody>
      </StyledTable>
      {props.editable && (
        <EditWorkModal
          open={!!workToEdit}
          onClose={() => setWorkToEdit(undefined)}
          workToEdit={workToEdit}
        />
      )}
    </div>
  )
}

export default WorkTable

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
`
const StyledTableCell = styled(TableCell)<{ $alignRight?: boolean }>`
  border: none;
  padding: 0;
  text-align: ${(props) => (props.$alignRight ? "right" : "left")};
`

const StyledTableCellHeader = styled(StyledTableCell)`
  padding: ${(props) => props.theme.spacing(1, 1)};
  padding-right: ${(props) =>
    props.$alignRight ? props.theme.spacing(3) : props.theme.spacing(1)};
`
